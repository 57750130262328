import AppCookies from '/static/js/component/component-cookies.min.js';
import AppHeight from '/static/js/utils/util-height.min.js';
import AppGeo from '/static/js/utils/util-geo.min.js'; 
import PrimaryHeader from '/static/js/component/component-header.min.js';
import HtmlInjectLoader from '/static/js/utils/util-html-inject-loader.min.js';
import ComponentLocationDropdown from '/static/js/component/component-location-dropdown.min.js';
import AppTracking from '/static/js/utils/util-tracking.min.js';


// Initiate Global Libraries
new AppCookies();
new AppHeight();
new AppGeo();
new HtmlInjectLoader();
new AppTracking();
new PrimaryHeader();

// Initialise all venue selectors dropdowns on the page.
let elements = document.querySelectorAll('.select-venue');
for(var i = 0; i < elements.length; i++) {
    new ComponentLocationDropdown(elements[i]);
}
